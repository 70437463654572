/*
 * Global context for account info with custom hook for data subscription
 * Inspired by https://kentcdodds.com/blog/how-to-use-react-context-effectively
 * */
import React, { FC, useCallback, useMemo } from 'react';
import { AccountType, Company, IdentityDetails, InternalTeam, Marketer, User, UserType } from '@mayple/types';
import { ApolloError } from 'apollo-client';

import { useActingMarketer, useActingInternalTeam, useActingCompany, useIdentityDetails, useViewer } from './hooks';
import getActingAccountTypeFromIdentityDetails from './logic';

export interface IActingAccountContext {
  actingCompany?: Company;
  actingMarketer?: Marketer;
  actingInternalTeam?: InternalTeam;
  actingAccount?: Marketer | Company | InternalTeam;
  identityDetails?: IdentityDetails;
  viewer?: User;
  error?: ApolloError;
  accountType: AccountType | null;
  isAdmin: boolean;
  loading: boolean;
  refetch: () => Promise<any>;
}

const ActingAccountContext = React.createContext<IActingAccountContext | undefined>(undefined);

const ActingAccountProvider: FC = ({ children }) => {
  const { identityDetails, loadingIdentityDetails, refetchIdentityDetails, errorIdentityDetails } =
    useIdentityDetails();

  const shouldSkip = !identityDetails?.userId;

  const { viewer, loadingViewer, refetchViewer, errorViewer } = useViewer(shouldSkip);

  const accountType = useMemo(() => getActingAccountTypeFromIdentityDetails(identityDetails), [identityDetails]);

  const { actingMarketer, loadingActingMarketer, refetchActingMarketer, errorActingMarketer } = useActingMarketer(
    accountType !== AccountType.MARKETER,
  );

  const { actingCompany, loadingActingCompany, refetchActingCompany, errorActingCompany } = useActingCompany({
    skip: accountType !== AccountType.COMPANY,
    notifyOnNetworkStatusChange: true,
  });

  const { actingInternalTeam, loadingActingInternalTeam, refetchActingInternalTeam, errorActingInternalTeam } =
    useActingInternalTeam(accountType !== AccountType.INTERNAL_TEAM);

  const loading =
    loadingIdentityDetails ||
    loadingActingMarketer ||
    loadingActingCompany ||
    loadingActingInternalTeam ||
    loadingViewer;

  const isAdmin = identityDetails?.userType === UserType.ADMIN_USER;

  const refetch = useCallback(async () => {
    await refetchIdentityDetails();

    return Promise.all([refetchViewer(), refetchActingMarketer(), refetchActingCompany(), refetchActingInternalTeam()]);
  }, [refetchActingCompany, refetchActingInternalTeam, refetchActingMarketer, refetchIdentityDetails, refetchViewer]);

  const value = useMemo(
    () => ({
      accountType,
      isAdmin,
      loading,
      refetch,
      actingInternalTeam,
      actingMarketer,
      actingCompany,
      identityDetails,
      viewer,
      actingAccount: actingInternalTeam || actingMarketer || actingCompany,
      error:
        errorIdentityDetails || errorViewer || errorActingMarketer || errorActingCompany || errorActingInternalTeam,
    }),
    [
      accountType,
      actingCompany,
      actingInternalTeam,
      actingMarketer,
      errorActingCompany,
      errorActingInternalTeam,
      errorActingMarketer,
      errorIdentityDetails,
      errorViewer,
      identityDetails,
      isAdmin,
      loading,
      refetch,
      viewer,
    ],
  );

  return <ActingAccountContext.Provider value={value}>{children}</ActingAccountContext.Provider>;
};

export { ActingAccountProvider, ActingAccountContext };
