import React from 'react';
import { SharedFileCategory } from '@mayple/types';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import capitalize from 'lodash/capitalize';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import result from 'lodash/result';
import find from 'lodash/find';
import axios from 'axios';
import get from 'lodash/get';
import normalizeUrlFunc from 'normalize-url';
import Cookies from 'js-cookie';
import psl from 'psl';

import { clientLogger, handleClientError } from '../logger';
import { svgs } from '../../app/svg';
import { COUNTRIES_LABELS_LOOKUP, SUPPORTED_CURRENCIES } from '../../app/enums';
import { APP_COOKIES, SHARED_FILE_MIME_TYPE } from '../../app/consts';
import { GOOGLE_SLIDE_REGEXP } from '../../components/display/GoogleSlideshowEmbed/logic';
import { datesDiffInDays, datesDiffInHours, datesDiffInMinutes } from '../../logic/dateUtils';
import { INDUSTRIES_LABELS_LOOKUP } from '../../logic/industries';

// TODO: Split this file into logic files, and kill it

/**
 * Can be used to make sure a param is sent to the function
 * look at https://davidwalsh.name/javascript-tricks for example.
 */
const isRequired = () => {
  throw new Error('parameter is required');
};

/*
 Example:
 const hello = (name = isRequired()) => { console.log(`hello ${name}`) };

 // This will throw an error because no name is provided
 hello();
 */

export function toNiceFloat(number) {
  // if null or undefined
  if (number == null) {
    return 0;
  }
  // make sure to use USA locale to format the numbers same to everyone
  // For example what happened for an Italian client, where they use ',' instead of '.' and vice versa. so stupid..
  // https://mayple.slack.com/archives/C9MB3E6HE/p1657027760307049
  return parseFloat(parseFloat(number).toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2 });
}

export function toNiceInt(number) {
  return parseInt(number, 10).toLocaleString();
}

export function randomChoice(choices = isRequired()) {
  return choices[Math.floor(Math.random() * choices.length)];
}

export function formatCurrencyString(number = 0, currencyType = '$') {
  // return `${currencyType} ${number.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
  return `${currencyType}${toNiceFloat(number)}`; // previous version
}

export function formatFloatCurrency(number = 0, currencyType = '$') {
  return `${currencyType}${toNiceFloat(number)}`;
}

export const resolveCurrencySymbol = (currency = 'USD') =>
  result(find(SUPPORTED_CURRENCIES, { value: currency }), 'symbol', '$');

export const resolveCurrencyName = (currency = 'USD') =>
  result(find(SUPPORTED_CURRENCIES, { value: currency }), 'name', 'US Dollar');

export const safeExtractCurrencyValue = (value, currency, withDecimal = true) => {
  const res = value != null ? formatFloatCurrency(value, resolveCurrencySymbol(currency)) : '';

  if (!withDecimal) {
    return res.split('.')[0];
  }

  return res;
};

export function formatPercentageString(percentageFraction) {
  return `${(percentageFraction * 100.0).toFixed(2)}%`;
}

export const isKpiPositive = (targetKpi) => targetKpi === 'ROAS';

export const formatKpiValuePercentageString = (targetKpi, percentageFraction = 0, withSign = true) => {
  if (typeof percentageFraction !== 'number') {
    return '';
  }

  const isPositive = isKpiPositive(targetKpi);
  const sign = isPositive ? '+' : '-';
  return `${withSign ? sign : ''}${(percentageFraction * 100.0).toFixed(0)}%`;
};

// You should hand to the function the result from formatKpiValuePercentageString
export const formatKpiValueAsNode = (kpiValue, className) => {
  const sign = kpiValue.substr(0, 1);
  const value = kpiValue.substr(1);

  return (
    <React.Fragment>
      <span className={className}>{sign}</span>
      {value}
    </React.Fragment>
  );
};

export function formatDateString(date, onlyDate = false) {
  if (!moment(date).isValid()) {
    return 'N/A';
  }

  if (onlyDate) {
    // example Nov 1, 2020
    return moment(date).format('MMM D, YYYY');
  } else {
    // example Nov 1, 2020: 3:12 PM
    return moment(date).format('MMM D, YYYY h:mm A');
  }
}

export function getMonthStartDate(date = new Date().toISOString()) {
  return moment.utc(date).clone().startOf('month').format('YYYY-MM-DD');
}

export function toIsoString(date = new Date()) {
  if (!date) {
    // eslint-disable-next-line no-param-reassign
    date = new Date();
  }
  return moment(date, true).utc().format();
}

export function formatBooleanString(boolean) {
  return boolean ? 'Yes' : 'No';
}

export function titleCase(string) {
  return string ? startCase(lowerCase(string.trim())) : '';
}

export function capitalizeString(string) {
  return string ? capitalize(string.trim()) : '';
}

// base64 encoded ascii to ucs-2 string
export function atou(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

const _urlNormalizingOptions = { stripWWW: false, defaultProtocol: 'https:', normalizeProtocol: true };

export function normalizeUrl(url, defaultUrl = '') {
  try {
    if (!url) {
      return defaultUrl;
    }
    return normalizeUrlFunc(url, _urlNormalizingOptions);
  } catch (e) {
    return defaultUrl;
  }
}

// Extracted from https://github.com/sindresorhus/prepend-http instead of installing
export function prependHttp(url, { https = true } = {}) {
  if (typeof url !== 'string') {
    throw new TypeError(`Expected \`url\` to be of type \`string\`, got \`${typeof url}\``);
  }

  const finalUrl = url.trim();

  if (/^\.*\/|^(?!localhost)\w+?:/.test(finalUrl) || finalUrl === '#') {
    return finalUrl;
  }

  return finalUrl.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
}

// export function getRandomLogoImageUrl() {
//   const s3Url = 'https://s3.amazonaws.com/perfpie-static-assets/images/logos';
//   const logoImageUrls = [
//     `${s3Url}/logo1.png`,
//     `${s3Url}/logo2.png`,
//     `${s3Url}/logo3.png`,
//     `${s3Url}/logo4.png`,
//     `${s3Url}/logo5.png`,
//     `${s3Url}/logo6.png`,
//     `${s3Url}/logo7.png`,
//     `${s3Url}/logo8.png`,
//     `${s3Url}/logo9.png`,
//     `${s3Url}/logo10.png`,
//     `${s3Url}/logo11.png`,
//     `${s3Url}/logo12.png`,
//     `${s3Url}/logo13.png`,
//     `${s3Url}/logo14.png`,
//     `${s3Url}/logo15.png`,
//     `${s3Url}/logo16.png`,
//     `${s3Url}/logo17.png`,
//     `${s3Url}/logo18.png`,
//     `${s3Url}/logo19.jpg`,
//     `${s3Url}/logo20.png`,
//     `${s3Url}/logo21.jpg`,
//     `${s3Url}/logo22.png`,
//     `${s3Url}/logo23.png`,
//     `${s3Url}/logo24.png`,
//     `${s3Url}/logo25.png`,
//     `${s3Url}/logo26.jpg`,
//     `${s3Url}/logo27.png`,
//     `${s3Url}/logo28.png`,
//     `${s3Url}/logo29.png`,
//     `${s3Url}/logo30.png`,
//     `${s3Url}/logo31.jpg`,
//     `${s3Url}/logo32.png`,
//     `${s3Url}/logo33.png`,
//     `${s3Url}/logo34.png`,
//     `${s3Url}/logo35.jpg`,
//     `${s3Url}/logo36.png`,
//     `${s3Url}/logo37.jpg`,
//     `${s3Url}/logo38.jpg`,
//     `${s3Url}/logo39.gif`,
//     `${s3Url}/logo40.png`,
//     `${s3Url}/logo41.png`,
//     `${s3Url}/logo42.jpg`,
//     `${s3Url}/logo43.jpg`,
//     `${s3Url}/logo44.png`,
//     `${s3Url}/logo45.png`,
//     `${s3Url}/logo46.jpg`,
//     `${s3Url}/logo47.jpg`,
//   ];
//
//   return randomChoice(logoImageUrls);
// }

/**
 * will reduce an array of objects to object with keys based on selected shared key from the objects in the array
 * @param array {Array} - list of objects
 * @param key {String} - should be a key in the objects with a STRING value!
 * @param booleanValues {Boolean} - To return boolean values in hash table
 */
export const arrayOfObjectsToObject = (array, key = 'value', booleanValues = false) => {
  if (!isArray(array) || typeof key !== 'string') {
    return {};
  }
  return array.reduce((obj, item) => {
    // eslint-disable-next-line no-param-reassign
    obj[item[key]] = booleanValues ? true : item;
    return obj;
  }, {});
};
/**
 * will reduce an array of objects to object with keys based on selected shared key from the objects in the array
 * @param array {Array} - list of objects
 * @param key {String} - should be a key in the objects with a STRING value!
 * @param toBoolean {Boolean} - To return boolean values in hash table - better performance if not using the values.
 */
export const arrayOfObjectsToHashTable = ({ array, key = 'value', toBoolean = false }) => {
  // Verify parameters
  if (!isArray(array) || typeof key !== 'string') {
    return {};
  }
  // Reduce the array to an object
  return array.reduce((obj, item) => {
    // Disabling the rule here, it does not make sense to create a new object each iteration.
    // eslint-disable-next-line no-param-reassign
    obj[item[key]] = toBoolean ? true : item;
    return obj;
  }, {});
};

// Returns the best object, merged out of several. Best means picking the best value for each key from each object,
// prefering values over null
export function mergeObjectsToBestObject(arrayOfObjects) {
  return arrayOfObjects.reduce(
    (mergedObject, currentObject) =>
      mergeWith(
        mergedObject,
        currentObject,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (firstValue, secondValue) => {
          // By returning undefined, we let the standard merge algorithm run
          let mergedValue = secondValue || firstValue;
          if (mergedValue === null) {
            mergedValue = undefined;
          }

          return mergedValue;
        },
      ),
    {},
  );
}

/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 * (The hash value of the empty string is zero.)
 * @param str
 * @returns {number}
 */
export const stringHashCode = (str) => {
  let hash = 5381;
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) + hash + char;
    /* hash * 33 + c */
  }
  return hash;
};

/**
 * will format to a currency or a simple nice int based on the targetKPI type
 * @param targetKPI {string}
 * @param targetKPIValue {number}
 * @returns {String}
 */
export const formatTargetKPIValue = ({ targetKPI, targetKPIValue }) => {
  if ((!targetKPIValue && targetKPIValue !== 0) || !targetKPI) {
    return 'No KPI value';
  }
  if (['ROAS', 'ROI'].includes(targetKPI)) {
    return toNiceFloat(targetKPIValue);
  }
  if (['Clicks', 'Conversions'].includes(targetKPI)) {
    return toNiceInt(targetKPIValue);
  }
  return formatFloatCurrency(targetKPIValue, '$');
};

/**
 * will create link to ad/client account based on project life cycle
 * @param project {object}
 * @param mediaChannel {string}
 * @returns {string}
 */
export const createAssetsLinksByLifeCycle = (project, mediaChannel) => {
  const { projectLifeCycleStatus } = project;

  // return the created channel ad accounts if there are any
  if (['ONBOARDING', 'LIVE'].includes(projectLifeCycleStatus)) {
    const channelAccounts = get(project, 'participants[0].channelAccounts', null);

    if (!channelAccounts || channelAccounts.length < 1) {
      return '#';
    } else {
      for (const channel of channelAccounts) {
        if (channel.channelType === mediaChannel) {
          const adAccountUrl = get(channel, 'integrationSettings.adAccountUrl', '#');
          return adAccountUrl || '#';
        }
      }
    }
  }

  // return the shared ad accounts the business choose on project integrations
  if (['DISCOVERY'].includes(projectLifeCycleStatus)) {
    const { marketingHistoryAccounts } = project;

    switch (mediaChannel) {
      case 'FACEBOOK_MARKETING': {
        const adAccountId = get(marketingHistoryAccounts, 'facebookMarketingAdAccounts[0].facebookAdAccountId', '#');
        return `https://wwww.facebook.com/adsmanager/manage/campaigns?${adAccountId}`;
      }
      case 'GOOGLE_ADWORDS': {
        const clientAccountId = get(
          marketingHistoryAccounts,
          'googleAdWordsClientAccounts[0].googleAdWordsClientAccountId',
          '#',
        );
        return `https://adwords.google.com/aw/overview?__e=${clientAccountId}`;
      }

      default:
        return '#';
    }
  }

  return '#';
};

/**
 * calculated days difference between 2 dates sent.
 * can send only 1 date do calc the diff between today
 * @param date1
 * @param date2
 * @returns {number} in days
 */
export const daysBetweenTwoDates = (date1 = new Date(), date2 = new Date()) => {
  const date1InMS = new Date(date1).getTime();
  const date2InMS = new Date(date2).getTime();

  return Math.round((date2InMS - date1InMS) / 1000 / 60 / 60 / 24); // return in days
};

/**
 * checks if marketer has fully integrated both AW and FB
 * @param defaultIntegrations
 * @returns {boolean}
 */
export const checkIfMarketerIntegrated = (defaultIntegrations) => {
  const {
    facebookManualIntegration,
    facebookMarketingIntegration,
    googleAdWordsIntegration,
    googleAdWordsManualIntegration,
  } = defaultIntegrations;

  let integratedFacebook = false;
  let integratedAdWords = false;

  if (facebookManualIntegration) {
    integratedFacebook = true;
  } else if (facebookMarketingIntegration) {
    // check if marketer set default BM
    if (get(facebookMarketingIntegration, 'defaultIntegrationSettings.facebookBusinessManagerId')) {
      integratedFacebook = true;
    }
  }
  if (googleAdWordsManualIntegration) {
    integratedAdWords = true;
  } else if (googleAdWordsIntegration) {
    // check if marketer set default MA
    if (get(googleAdWordsIntegration, 'defaultIntegrationSettings.googleAdWordsManagerAccountId')) {
      integratedAdWords = true;
    }
  }
  return integratedAdWords && integratedFacebook;
};

/**
 * returns an image url of the credit card preview
 * @param cardType - one of the cardUrl fields.
 * @returns {*|string}
 */
export const getCreditCardPreviewUrl = (cardType) => {
  const link = 'https://files.readme.io'; // saving some bundle size
  const cardUrl = {
    AMERICAN_EXPRESS: `${link}/97e7acc-Amex.png`,
    CARTE_BLEAU: `${link}/5da1081-cb.png`,
    DINERS: `${link}/8c73810-Diners_Club.png`,
    DISCOVER: `${link}/caea86d-Discover.png`,
    JCB: `${link}/e076aed-JCB.png`,
    MAESTRO_UK: `${link}/daeabbd-Maestro.png`,
    MASTERCARD: `${link}/5b7b3de-Mastercard.png`,
    SOLO: 'https://sandbox.bluesnap.com/services/hosted-payment-fields/cc-types/solo.png',
    VISA: `${link}/9018c4f-Visa.png`,
  };
  return cardUrl[cardType] || svgs.credit_card;
};

/**
 * To create unique names for projects, due to the fact there is no step to fill the project name in the flow
 * O(n) complexity
 * @param projectGoal
 * @param existingProjects
 */
export const createProjectName = (projectGoal, existingProjects = []) => {
  // create hash table of names
  const namesObj = arrayOfObjectsToObject(existingProjects, 'name');

  // if name already exists as projectGoal
  if (namesObj[projectGoal]) {
    let occurrence = 2;

    // check for other occurrences of the same projectGoal
    while (namesObj[`${projectGoal} ${occurrence}`]) {
      occurrence += 1;
    }

    // return the new project name
    return `${projectGoal} ${occurrence}`;
  } else {
    // create the first occurrence
    return projectGoal;
  }
};

// DEPRECATED
// TODO: Remove this after we verify all projects are using new method
// see: src/fe_common/client/logic/fundAccount.js:12
/**
 * checks if fund account has at least 1 active payment agreement and return it
 * if nothing found, return null
 * @param fundAccount {Object}
 * @returns {Object}
 */
export const getActivePaymentAgreement = (fundAccount = {}) => {
  const { creditCardPaymentAgreements = [], paypalOpenPaymentAgreements = [] } = fundAccount;
  // let hasAtLeastOneActiveAgreement = false;
  let currentActiveAgreement = null;
  // need to verify that company has at least 1 active and valid credit card payment agreement
  if (creditCardPaymentAgreements) {
    for (const ccAgreement of creditCardPaymentAgreements) {
      const { active, valid } = ccAgreement;
      if (active && valid) {
        // hasAtLeastOneActiveAgreement = true;
        currentActiveAgreement = ccAgreement;
        break; // eslint-disable-line no-restricted-syntax
      }
    }
  }
  if (!currentActiveAgreement && paypalOpenPaymentAgreements) {
    for (const paypalAgreement of paypalOpenPaymentAgreements) {
      const { active, valid } = paypalAgreement;
      if (active && valid) {
        // hasAtLeastOneActiveAgreement = true;
        currentActiveAgreement = paypalAgreement;
        break; // eslint-disable-line no-restricted-syntax
      }
    }
  }

  return currentActiveAgreement;
};

/**
 * Extracts the values from the array of enum objects to array of strings.
 * @param arr
 * @param valueKey - string representing the key where value is stored
 * @returns {*|Array}
 */
export const valuesOnly = (arr, valueKey = 'value') => {
  try {
    if (!arr) {
      return [];
    }
    return arr.map((obj) => obj[valueKey]) || [];
  } catch (e) {
    return [];
  }
};

/**
 *
 * @param {String} hash
 */
export const changeUrlHash = (hash) => {
  if (window.history.pushState) {
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${hash}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
};

/**
 * Returns a nice list of countries
 * @param countries
 * @returns {string|*}
 */
export const toNiceCountriesList = (countries) => {
  if (!countries || countries.length === 0) {
    return 'N/A';
  }
  if (countries.length === 1) {
    return COUNTRIES_LABELS_LOOKUP[countries[0]];
  }

  const niceList = countries.map((item, index) => {
    // For the last item
    if (index === countries.length - 1) {
      return `and ${COUNTRIES_LABELS_LOOKUP[item]}`;
    }
    // for 1 before last, dont add comma
    if (index === countries.length - 2) {
      return `${COUNTRIES_LABELS_LOOKUP[item]} `;
    }
    return `${COUNTRIES_LABELS_LOOKUP[item]}, `;
  });
  // Return as string
  return niceList.join('');
};

/**
 * Returns a nice list of enums with nice labels
 * @param enums
 * @param labelsLookup
 * @returns {string|*}
 */
export const toNiceEnumsList = (enums, labelsLookup) => {
  if (!enums || enums.length === 0) {
    return 'N/A';
  }
  if (enums.length === 1) {
    return labelsLookup[enums[0]];
  }

  const niceList = enums.map((item, index) => {
    // For the last item
    if (index === enums.length - 1) {
      return `and ${labelsLookup[item]}`;
    }
    // for 1 before last, dont add comma
    if (index === enums.length - 2) {
      return `${labelsLookup[item]} `;
    }
    return `${labelsLookup[item]}, `;
  });
  // Return as string
  return niceList.join('');
};

export const getFromLocalStorage = (propertyName) => {
  try {
    if (!window.localStorage) {
      clientLogger.debug('Current browser does not support local storage');
      return null;
    }

    return JSON.parse(localStorage.getItem(propertyName)) || null;
  } catch (e) {
    handleClientError(e);
    return null;
  }
};

export const removeFromLocalStorage = (propertyName) => {
  try {
    if (!window.localStorage) {
      clientLogger.debug('Current browser does not support local storage');
      return;
    }

    localStorage.removeItem(propertyName);
  } catch (e) {
    handleClientError(e);
  }
};

export const setToLocalStorage = (key, value) => {
  try {
    if (!window.localStorage) {
      clientLogger.debug('Current browser does not support local storage');
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    handleClientError(e);
  }
};

export const getFromSessionStorage = (propertyName) => {
  try {
    if (!window.sessionStorage) {
      clientLogger.debug('Current browser does not support session storage');
      return null;
    }

    return JSON.parse(sessionStorage.getItem(propertyName)) || null;
  } catch (e) {
    handleClientError(e);
    return null;
  }
};

export const removeFromSessionStorage = (propertyName) => {
  try {
    if (!window.sessionStorage) {
      clientLogger.debug('Current browser does not support session storage');
      return;
    }

    sessionStorage.removeItem(propertyName);
  } catch (e) {
    handleClientError(e);
  }
};

export const setToSessionStorage = (key, value) => {
  try {
    if (!window.sessionStorage) {
      clientLogger.debug('Current browser does not support session storage');
      return;
    }

    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    handleClientError(e);
  }
};

// Transfers the phone number from +972123456789 to (+972)12-345-67-89
export const parsePhoneNumberFromText = (phoneNumber) => {
  const regExp = /\+(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/g;
  return phoneNumber.replace(regExp, '(+$1)$2-$3-$4-$5');
};

// Defines the activeStep & subSteps inside the marketer wizard stepper.
export class ActiveStep {
  constructor(currentStep = 0) {
    this.currentStep = currentStep;
    this.subSteps = [];
    /* subStep is an object array shapes as:
     [{ label: 'FatherStepLabel', subLabels: [{ subLabel: 'subStep1', isActive: true } ,
     { subLabel: 'subStep2', isActive: false }]}] */
  }

  increment() {
    this.currentStep += 1;
  }

  setCurrentStep(newStep) {
    this.currentStep = newStep;
  }

  addSubStep(subStep) {
    this.subSteps.push(subStep);
  }

  getSubStepsByMainLabel(label) {
    return this.subSteps.filter((subStep) => subStep.label === label);
  }

  activateSubStepByLabelAndIndex(label, idx) {
    const subStep = this.getSubStepsByMainLabel(label)[0].subLabels[idx] || {};
    subStep.isActive = !subStep.isActive;
  }
}

// format Google AdWords asset id from 1234567890 to 123-456-7890
export const formatGoogleAdWordsAssetId = (assetId) => {
  const regExp = /(\d{3})(\d+)(\d{4})/g;
  return assetId.replace(regExp, '$1-$2-$3');
};

export const geoIpLookup = (onComplete, defaultCountry = 'GB') => {
  const { protocol } = window.location;

  axios(`${protocol}//ipinfo.io/json`, { method: 'GET', mode: 'cors' })
    .then((response) => response.data)
    .then((json) => {
      let country = json && json.country ? json.country : defaultCountry;
      if (country === 'PS') {
        country = 'IL';
      }
      onComplete(country);
    })
    .catch(() => {
      onComplete(defaultCountry);
    });
};

// eslint-disable-next-line consistent-return
export const downloadFileByUrl = (fileUrl, fileName) => {
  // function to download any file from a given url with a selected file-name.
  // file name needs to be given with the right .filetype at the end.

  try {
    return axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob', // important
    })
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        } else {
          throw new Error(`Server error status code - ${response.status}`);
        }
      })
      .catch((error) => {
        throw new Error(`Server error - ${error}`);
      });
  } catch (error) {
    clientLogger.debug(`Your download has failed with ${error.message}, please contact a mayple representative`);
  }
};

const appendPdfExtension = (name) => (!/\.pdf/.test(name) ? `${name}.pdf` : name);

export const getExportFileName = (file) => {
  if (file?.mimetype === SHARED_FILE_MIME_TYPE.GOOGLE_SLIDES) {
    return appendPdfExtension(file?.name || file?.filename || file?.fileName) || 'download.pdf';
  }

  return file?.name || file?.filename || file?.fileName || 'download.pdf';
};

export const getExportFileUrl = (file) => {
  if (file && file.mimetype === SHARED_FILE_MIME_TYPE.GOOGLE_SLIDES) {
    return (file?.url || file?.fileUrl || '').replace(GOOGLE_SLIDE_REGEXP, '$1/export/pdf');
  }

  return file?.url || file?.fileUrl || '';
};

export const fileOnDownloadHandler = async (event, file) => {
  event.stopPropagation();
  await downloadFileByUrl(getExportFileUrl(file), getExportFileName(file));
};

export const formattedDate = (dateObject, joinBy) =>
  // function to format the date to requested form of dd/mm/yyyy
  // dateObject parameter should be new Date(Input_Date) or already a Date Object.
  // joinBy parameter decides the splitting char between date sections.

  [dateObject.getDate(), dateObject.getMonth() + 1, dateObject.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join(joinBy);

export const getTimeLeftToMeeting = (meetingDate) => {
  const now = moment.utc();
  const meeting = moment.utc(meetingDate);
  const timeLeftInDays = datesDiffInDays(now.toDate(), meeting.toDate());
  const timeLeftInHours = datesDiffInHours(now.toDate(), meeting.toDate());
  const timeLeftInMinutes = datesDiffInMinutes(now.toDate(), meeting.toDate());

  if (timeLeftInDays > 1) {
    return { timeLeft: timeLeftInDays, unit: 'days' };
  }
  if (timeLeftInHours > 1) {
    return { timeLeft: timeLeftInHours, unit: 'hours' };
  }
  if (timeLeftInHours === 1 && timeLeftInMinutes > 60) {
    return { timeLeft: timeLeftInHours, unit: 'hour' };
  }
  if (timeLeftInMinutes < 60 && timeLeftInMinutes > 1) {
    return { timeLeft: timeLeftInMinutes, unit: 'minutes' };
  }
  if (timeLeftInMinutes === 1) {
    return { timeLeft: timeLeftInMinutes, unit: 'minute' };
  }

  return { timeLeft: 0, unit: 'minutes' };
};

export function printMaypleCow() {
  // we use set time out and bind, to remove the line number from the console
  setTimeout(
    // eslint-disable-next-line no-console
    console.log.bind(
      console,
      `
            (__)
            (oo)
  /----------\\/    moo
 / ||Mayple ||
*  ||-------||
   ^^       ^^
`,
    ),
  );
}

export function shouldShowState(country) {
  return !!country && country?.value && ['US', 'CA'].includes(country.value);
}

export function hasProjectMarketingHistory(project) {
  const hasFBAssets = get(project, 'marketingHistoryAccounts.facebookMarketingAdAccounts', []).length > 0;
  const hasAWAssets = get(project, 'marketingHistoryAccounts.googleAdWordsClientAccounts', []).length > 0;

  return hasAWAssets || hasFBAssets;
}

export function hasProjectMarketingLiveAccounts(project) {
  return get(project, 'participants[0].channelAccounts', []).length > 0;
}

export function getLatestStrategyFile(sharedFiles = []) {
  const [strategy] = sharedFiles
    .filter(({ category }) => category === SharedFileCategory.MARKETING_STRATEGY)
    .sort((a, b) => {
      const dateA = new Date(a.created).getTime();
      const dateB = new Date(b.created).getTime();
      return dateA > dateB ? 1 : -1;
    });
  return strategy;
}

export const validateCookiesEnabled = (domain) => {
  // Some times user browsers block us from saving cookies.
  // First we attempt to save a test cookie to check if saving works.
  Cookies.set(APP_COOKIES.TEST_COOKIE, { test: 'data' }, { domain });
  const testCookie = Cookies.getJSON(APP_COOKIES.TEST_COOKIE);

  // if saving a cookie fails, then redirect the user to the app.
  if (!testCookie) {
    clientLogger.error('Error: unable to save test cookie');
    return false;
  }

  // otherwise, delete test cookie. all is good.
  clientLogger.debug('Browser cookie saving is allowed.');
  Cookies.remove(APP_COOKIES.TEST_COOKIE, { domain });
  return true;
};

export const getInitialValuesFromCookie = () => {
  const preFilledValuesCookie = Cookies.getJSON(APP_COOKIES.WEBSITE_PRE_FILLED_VALUES);
  if (!preFilledValuesCookie) {
    return {};
  }

  const cookieInitialValues = {};
  const entries = Object.entries(preFilledValuesCookie);
  entries.forEach((entry) => {
    const [key, value] = entry;
    cookieInitialValues[key] = value;
    cookieInitialValues[`${key}FromCookie`] = true;
  });

  return cookieInitialValues;
};

export const getSiteProtocol = () => {
  const { protocol } = window.location;
  return protocol.replace(':', '');
};

// https://www.facebook.com/Fiverr --> Fiverr
// https://www.facebook.com/TatranMusic --> TatranMusic
// https://www.facebook.com/cebupacificair/ --> cebupacificair
// https://www.facebook.com/קוקה-קולה-וניל-165911170095974/ --> קוקה-קולה-וניל - (remove the last number)
// https://www.facebook.com/pages/CredCompare/383919875559397 <-- CredCompare (don't take the number after the pagename)
// https://www.instagram.com/ddlovato -> ddlovato
// https://twitter.com/NICKIMINAJ/ --> NICKIMINAJ
// https://www.linkedin.com/in/omer-farkash-182281b4/ -> omer-farkash <- remove the hex part if found
// https://www.linkedin.com/in/tomernosrati/ --> tomernosrati https://www.linkedin.com/company/oracle --> oracle
// https://www.linkedin.com/company/reblaze/ --> reblaze
export const SOCIAL_NETWORK_PAGE_REGEX = /(www\.)?(facebook|twitter|linkedin|instagram|patreon)\.com/i;

export const getCompanyNameFromSocialNetworkUrl = (url) => {
  // getting the social network from the url
  const [, , socialNetwork] = url.match(SOCIAL_NETWORK_PAGE_REGEX);

  // defined regex for each social network
  const facebookRegex = /facebook\.com\/(pages\/)?([^/]*)(\/.*)?\/?$/i; // result will be in group 2
  const linkedinRegex = /linkedin\.com\/(in|company)\/([^/]*)\/?$/i; // result will be in group 2
  const instagramRegex = /instagram\.com\/([^/]*)\/?$/; // result will be in group 1
  const twitterRegex = /twitter\.com\/([^/]*)\/?$/; // result will be in group 1
  const patreonRegex = /patreon\.com\/([^/]*)\/?$/; // result will be in group 1

  // map social network to its regex and company name group position in the match
  const socialRegexMap = {
    facebook: { regex: facebookRegex, position: 2 },
    linkedin: { regex: linkedinRegex, position: 2 },
    instagram: { regex: instagramRegex, position: 1 },
    twitter: { regex: twitterRegex, position: 1 },
    patreon: { regex: patreonRegex, position: 1 },
  };

  let companyName = '';

  // we are doing here something that potentially can fail
  try {
    const { regex, position } = socialRegexMap[socialNetwork];
    companyName = url.match(regex)[position];
  } catch (e) {
    clientLogger.log('Failed retrieving company name from social network link');
    return url;
  }

  // companyName cleanup
  companyName = titleCase(companyName.replace(/-[\da-f]*$/i, ''));

  // prettify the company name
  return titleCase(companyName) || url;
};

/**
 * Return Company name out of the website url
 * fully supports following urls:
 *
 https://www.ukcampsite.co.uk/sites/index.asp
 https://en.wikipedia.org/wiki/List_of_World_Heritage_Sites_in_the_United_Kingdom
 https://www.campingandcaravanningclub.co.uk/ukcampsites
 https://uk.mydomain.com/ukcampsites
 https://uk.mydomain.co.uk/ukcampsites
 https://mail.walla.co.il/ukcampsites
 https://www.mydomain.co.uk/sdfsdf
 https://mysub.domain.tours/sdfsdf
 https://xn--fpcrj9c3d.tours/sdfsdf
 https://test.test.xn--fpcrj9c3d.tours/sdfsdf
 https://test.xn--fpcrj9c3d.dad/sd/sdf/sd/fs/df
 * @param url
 * @returns {string|*}
 */
export const getCompanyNameFromUrl = (url) => {
  let companyName = url;

  if (!url) {
    return companyName;
  }

  try {
    // Attempt to create a URL object from website url input
    const fullDomain = psl.get(new URL(url).hostname);

    // Trying to extract the domain name - might fail if invalid url
    const domain = fullDomain.split('.')[0];

    // Then prettify it
    companyName = titleCase(domain);
  } catch (e) {
    // If failed, set the name as the website address.
    companyName = '';
    clientLogger.error(e);
    clientLogger.error('Unable to set company name from website');
  }

  return companyName;
};

export function getCompanyName(websiteAddress) {
  let companyName = websiteAddress;

  // check if the websiteAddress is a social network url
  if (SOCIAL_NETWORK_PAGE_REGEX.test(websiteAddress)) {
    companyName = getCompanyNameFromSocialNetworkUrl(websiteAddress);
  }

  // might be that we failed extracting company name then by default we return the websiteAddress
  // now we are doing what we usually do with the URL
  if (companyName === websiteAddress) {
    companyName = getCompanyNameFromUrl(websiteAddress);
  }

  // generate a default company name just in case companyName || websiteAddress will be empty
  // Suspend defaultCompanyName creation - we prefer not to send anything if url is empty
  // const defaultCompanyName = `Company_${new Date().toISOString().replace(/T.*$/, '')}`;
  return companyName || websiteAddress; // || defaultCompanyName;
}

export const costReducer = (total, { cost }) => total + cost;

export const getNextItemsFromObjectsArray = (items, itemId, key, size = 3) => {
  let indexOfCurrentItem = items.findIndex((item) => item[key] === itemId);

  if (indexOfCurrentItem === -1) {
    indexOfCurrentItem = 0;
  }

  // if the total items array is shorter than the returned array size
  // we should filter out the current item, and return all other
  if (items.length - 1 < size) {
    return items.filter((item) => item[key] !== itemId);
  }

  const nextOneIndex = indexOfCurrentItem + 1;
  const nextSizeIndex = nextOneIndex + size;

  const loopItems = [...items, ...items];
  return loopItems.slice(nextOneIndex, nextSizeIndex);
};

export function tryParseInt(str, defaultValue) {
  let retValue = defaultValue;

  if (typeof str === 'number') {
    retValue = parseInt(str, 10);
  } else if (typeof str !== 'undefined' && str !== null) {
    if (str.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(str)) {
        retValue = parseInt(str, 10);
      }
    }
  }
  return retValue;
}

export function getIndustryLabel(industry) {
  if (typeof industry === 'string') {
    return INDUSTRIES_LABELS_LOOKUP[industry];
  }

  const path = Array.isArray(industry) ? '[0].industrySubCategory' : 'industrySubCategory';
  const industrySubCategory = get(industry, path);

  if (industrySubCategory) {
    return INDUSTRIES_LABELS_LOOKUP[industrySubCategory];
  }

  const categoryPath = Array.isArray(industry) ? '[0].industryCategory' : 'industryCategory';
  const industryCategory = get(industry, categoryPath);

  return INDUSTRIES_LABELS_LOOKUP[industryCategory || 'SERVICES_OTHER_1020'];
}

export const stripHtmlTags = (htmlStr = '') => {
  if (!htmlStr) {
    return '';
  }

  return htmlStr
    .toString()
    .replaceAll(/<\/?[\w\s]*>|<.+[\W]>/g, '')
    .trim();
};

export const waitXMilliSeconds = (ms = 1000) => {
  clientLogger.debug(`waitXMilliSeconds start ${ms}ms`);
  return new Promise((resolve) => {
    setTimeout(() => {
      clientLogger.debug(`waitXMilliSeconds end ${ms}ms`);
      resolve();
    }, ms);
  });
};

export function isEmptyObject(obj) {
  if (!obj) {
    return true;
  }

  return Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}

export const generateUuid = () => uuidV4().replace(/-/g, '');

export const asBool = (value) => {
  if (typeof value === 'string') {
    return value === 'true';
  }
  if (typeof value === 'number' || typeof value === 'bigint') {
    return value !== 0;
  }

  return !!value;
};
