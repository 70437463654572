import { useQuery, QueryHookOptions } from '@apollo/react-hooks';
import { ActingMarketerQuery } from 'growl-graphql/dist/queries/ActingMarketerQuery';
import { ActingCompanyQuery } from 'growl-graphql/dist/queries/ActingCompanyQuery';
import { ViewerUserQuery } from 'growl-graphql/dist/queries/ViewerUserQuery';
import { UpdateMarketerMutation } from 'growl-graphql/dist/mutations/UpdateMarketerMutation';
import { UpdateCompanyMutation } from 'growl-graphql/dist/mutations/UpdateCompanyMutation';
import { UpdateUserMutation } from 'growl-graphql/dist/mutations/UpdateUserMutation';

import { useCallback, useEffect, useState } from 'react';
import { useMutation } from './index';
import { clientLogger } from '../services/logger';

// eslint-disable-next-line no-shadow
export enum Entities {
  MARKETER = 'MARKETER',
  COMPANY = 'COMPANY',
  USER = 'USER',
}

export type ClientMetaDataEntities = Entities.MARKETER | Entities.COMPANY | Entities.USER;

type EntityQueries = {
  key: string;
  entityIdKey: string;
  entityUpdateKey: string;
  queryClass: any;
  mutationClass: any;
  responseKey: string;
};

const EntityQueriesMapper: Record<Entities, EntityQueries> = {
  [Entities.MARKETER]: {
    key: 'actingMarketer',
    entityIdKey: 'marketerId',
    entityUpdateKey: 'marketerUpdate',
    queryClass: ActingMarketerQuery,
    mutationClass: UpdateMarketerMutation,
    responseKey: 'updateMarketer',
  },
  [Entities.COMPANY]: {
    key: 'actingCompany',
    entityIdKey: 'companyId',
    entityUpdateKey: 'companyUpdate',
    queryClass: ActingCompanyQuery,
    mutationClass: UpdateCompanyMutation,
    responseKey: 'updateCompany',
  },
  [Entities.USER]: {
    key: 'viewer',
    entityIdKey: 'userId',
    entityUpdateKey: 'userUpdate',
    queryClass: ViewerUserQuery,
    mutationClass: UpdateUserMutation,
    responseKey: 'updateUser',
  },
};

// Created this to have the ability to use TS intelisense during development
// This is actually a documentation of the MarketerClientMetadata
export interface MarketerClientMetadata extends Record<string, any> {
  hideOpportunityReceivedDialog: boolean;
  opportunitiesDialogClosed: string[];
}

export type CompanyClientMetadata = Record<string, any>;

export type UserClientMetadata = Record<string, any>;

const wrapClientMetadata = (newData: Record<string, any>, prevData: Record<string, any>): Record<string, any> => ({
  clientMetadata: {
    ...prevData,
    ...newData,
  },
});

export type SetClientMetadata = (newData: Record<string, any>) => Promise<void>;

type ClientMetadataTuple = [
  MarketerClientMetadata | CompanyClientMetadata | UserClientMetadata,
  SetClientMetadata,
  boolean,
];

const useClientMetadata = <T extends Record<string, any>>(
  entity: ClientMetaDataEntities,
  queryOptions: QueryHookOptions = {
    fetchPolicy: 'network-only',
  },
): ClientMetadataTuple => {
  const [entityId, setEntityId] = useState<number | undefined>();
  const [clientMetadata, setClientMetadata] = useState<T>({} as T);

  const { key, entityIdKey, entityUpdateKey, queryClass, mutationClass, responseKey } = EntityQueriesMapper[entity];

  if (!entity || ![Entities.MARKETER, Entities.COMPANY, Entities.USER].includes(entity)) {
    throw new Error('Wrong entity type was provided to useClientMetadata');
  }

  const { data, loading } = useQuery(queryClass.query, queryOptions);

  const { mutate } = useMutation(mutationClass);

  const saveClientMetadata = useCallback(
    async (newData: Record<string, any>): Promise<void> => {
      try {
        const variables = {
          [entityIdKey]: entityId,
          [entityUpdateKey]: wrapClientMetadata(newData, clientMetadata),
        };

        const result = await mutate({ variables });
        const newClientMetadata = result?.data?.[responseKey]?.clientMetadata || {};

        setClientMetadata(newClientMetadata);
      } catch (e) {
        clientLogger.error(e, 'Error while trying to save client metadata');
      }
    },
    [entityIdKey, entityId, entityUpdateKey, clientMetadata, mutate, responseKey],
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    const newEntityId = data?.[key]?.id || 0;
    const newClientMetadata = data?.[key]?.clientMetadata || {};

    setEntityId(newEntityId);
    setClientMetadata(newClientMetadata);
  }, [data, loading, key]);

  const isReady = Boolean(entityId) && !loading;

  return [clientMetadata as T, saveClientMetadata, isReady];
};

export default useClientMetadata;
